export const serviceTypes = {
  FETCH_SERVICES_STARTED: "FETCH_SERVICES_STARTED",
  FETCH_SERVICES_SUCCESS: "FETCH_SERVICES_SUCCESS",
  FETCH_SERVICES_FAILED: "FETCH_SERVICES_FAILED",

  FETCH_SERVICES_CAT_STARTED:"FETCH_SERVICES_CAT_STARTED",
  FETCH_SERVICES_CAT_FAILED:"FETCH_SERVICES_CAT_FAILED", 
  FETCH_SERVICES_CAT_SUCCESS:"FETCH_SERVICES_CAT_SUCCESS", 

  FETCH_SERVICES_CLASS_STARTED:"FETCH_SERVICES_CLASS_STARTED", 
  FETCH_SERVICES_CLASS_FAILED:"FETCH_SERVICES_CLASS_FAILED", 
  FETCH_SERVICES_CLASS_SUCCESS:"FETCH_SERVICES_CLASS_SUCCESS",

  FETCH_SERVICES_TAXHEAD_STARTED:"FETCH_SERVICES_TAXHEAD_STARTED",
  FETCH_SERVICES_TAXHEAD_SUCCESS:"FETCH_SERVICES_TAXHEAD_SUCCESS",
  FETCH_SERVICES_TAXHEAD_FAILED:"FETCH_SERVICES_TAXHEAD_FAILED",

  FETCH_PAYMENT_OPTION_STARTED:"FETCH_PAYMENT_OPTION_STARTED", 
  FETCH_PAYMENT_OPTION_SUCCESS: "FETCH_PAYMENT_OPTION_SUCCESS", 
  FETCH_PAYMENT_OPTION_FAILED :"FETCH_PAYMENT_OPTION_FAILED",

  UPDATE_USER_INPUT_STARTED:"UPDATE_USER_INPUT_STARTED", 
  UPDATE_USER_INPUT_SUCCESS: "UPDATE_USER_INPUT_SUCCESS", 
  UPDATE_USER_INPUT_FAILED :"UPDATE_USER_INPUT_FAILED",

  RESET_USER_INPUT_STARTED:"RESET_USER_INPUT_STARTED", 
  RESET_USER_INPUT_SUCCESS: "RESET_USER_INPUT_SUCCESS", 
  RESET_USER_INPUT_FAILED :"RESET_USER_INPUT_FAILED",

  SUBMIT_APPLICATION_STARTED:"SUBMIT_APPLICATION_STARTED", 
  SUBMIT_APPLICATION_SUCCESS: "SUBMIT_APPLICATION_SUCCESS", 
  SUBMIT_APPLICATION_FAILED :"SUBMIT_APPLICATION_FAILED",

  GET_PRN_STARTED:"GET_PRN_STARTED", 
  GET_PRN_SUCCESS: "GET_PRN_SUCCESS", 
  GET_PRN_FAILED :"GET_PRN_FAILED",

  ATTACH_PRN_STARTED:"ATTACH_PRN_STARTED", 
  ATTACH_PRN_SUCCESS: "ATTACH_PRN_SUCCESS", 
  ATTACH_PRN_FAILED :"ATTACH_PRN_FAILED",


  VALIDATE_STEP_STARTED:"VALIDATE_STEP_STARTED", 
  VALIDATE_STEP_SUCCESS: "VALIDATE_STEP_SUCCESS", 
  VALIDATE_STEP_FAILED :"VALIDATE_STEP_FAILED",

  VERIFY_NUMBER_PLATE_STARTED:"VERIFY_NUMBER_PLATE_STARTED", 
  VERIFY_NUMBER_PLATE_SUCCESS: "VERIFY_NUMBER_PLATE_SUCCESS", 
  VERIFY_NUMBER_PLATE_FAILED :"VERIFY_NUMBER_PLATE_FAILED",

  VERIFY_NIN_STARTED:"VERIFY_NIN_STARTED", 
  VERIFY_NIN_SUCCESS: "VERIFY_NIN_SUCCESS", 
  VERIFY_NIN_FAILED :"VERIFY_NIN_FAILED",


  GET_APP_CONFIRMATION_STARTED:"GET_APP_CONFIRMATION_STARTED", 
  GET_APP_CONFIRMATION_SUCCESS: "GET_APP_CONFIRMATION_SUCCESS", 
  GET_APP_CONFIRMATION_FAILED :"GET_APP_CONFIRMATION_FAILED",

  GET_DASHBOARD_STATS_STARTED: "GET_DASHBOARD_STATS_STARTED",
  GET_DASHBOARD_STATS_FAILED: "GET_DASHBOARD_STATS_FAILED",
  GET_DASHBOARD_STATS_SUCCESS: "GET_DASHBOARD_STATS_SUCCESS",
}